import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import Markdown from 'markdown-to-jsx'
import { useTranslation } from '../../../../services/translation'
import moment from 'moment'
import { useRouter } from 'next/router'
import { toRealFormat } from '../../../../utils/currency-format.util'
import styles from './style.module.scss'

const READ_NOTIF = gql`
  mutation readNotification($notificationId: ID!) {
    readNotification(notificationId: $notificationId)
  }
`

export const NotificationItem = ({
  notification,
  refreshNotifications,
  onClick,
}) => {
  const { t, lang } = useTranslation()
  const router = useRouter()
  const [notificationRead] = useMutation(READ_NOTIF)

  const getDateDiff = () => {
    const diff = moment().diff(moment(notification.createdAt))
    return moment.duration(diff)
  }

  const getDateDiffText = () => {
    const duration = getDateDiff()
    const minutes = Math.floor(duration.asMinutes())
    if (minutes < 1) return t('notifications.diff.seconds')
    if (minutes === 1) return t('notifications.diff.minute')
    const hours = Math.floor(duration.asHours())
    if (hours < 1)
      return t('notifications.diff.minutes', {
        minutes,
      })
    if (hours === 1) return t('notifications.diff.hour')
    const days = Math.floor(duration.asDays())
    if (days < 1)
      return t('notifications.diff.hours', {
        hours,
      })
    if (days === 1) return t('notifications.diff.day')
    const weeks = Math.floor(duration.asWeeks())
    if (weeks < 1)
      return t('notifications.diff.days', {
        days,
      })
    if (weeks === 1) return t('notifications.diff.week')
    const months = duration.asMonths()
    if (months < 1)
      return t('notifications.diff.weeks', {
        weeks,
      })
    return t('notifications.diff.month')
  }

  const [dateDiff, setDateDiff] = useState(getDateDiffText())

  useEffect(() => {
    const duration = getDateDiff()
    if (duration.asHours() < 1) {
      const timer = setTimeout(() => {
        const diffText = getDateDiffText()
        setDateDiff(diffText)
      }, 60000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [dateDiff])

  useEffect(() => {
    const diffText = getDateDiffText()
    setDateDiff(diffText)
  }, [lang])

  const isReadNotification = notification.readedAt === null

  const handleNotificationClick = async notification => {
    if (!notification.readedAt) {
      try {
        await notificationRead({
          variables: {
            notificationId: notification.id,
          },
        })
      } catch {}
    }

    redirectOnClick(notification)
    if (onClick != null) {
      onClick()
    }
  }

  const redirectOnClick = notification => {
    switch (notification.title) {
      case 'likeModel':
      case 'unlikeModel':
      case 'newModelProposal':
        router.push('/chipinorganize')
        break
      case 'acceptProposal':
      case 'acceptProposalExclusive':
      case 'newPurchaseToModeler':
      case 'newPurchaseToModelerFree':
      case 'needAdjustmentsOrganizer':
      case 'fileErrorReported':
      case 'modelLowFund':
      case 'deadlineIsComing':
      case 'adjustmentsDeadlineIsComing':
      case 'finalFilesDeadlineIsComing':
      case 'finishErrorReportedModeler':
      case 'exclusivityEnded':
        router.push('/chipinmodeling')
        break
      case 'newPurchaseToBuyer':
      case 'newPurchaseToBuyerFree':
        router.push('/chipinparticipation')
        break
      case 'needApproval':
        const role = notification.replacements.role
        if (role) {
          const url =
            role === 'organizer'
              ? '/chipinorganize'
              : role === 'modeler'
              ? '/chipinmodeling'
              : '/chipinparticipation'
          router.push(url)
        }
        break
      case 'finishedModel':
        router.push('/helpcontact')
        break
      case 'creditsPurchased':
      case 'withdrawRequested':
        router.push('/history')
        break
      case 'acceptModelVersion':
        router.push(`/m/${notification.replacements.modelSlug}`)
        break
      case 'refuseModelVersion':
        router.push(`/modeledit/${notification.replacements.modelId}`)
        break
    }

    refreshNotifications()
  }

  const getTreatedReplacements = () => {
    const replacements = { ...notification.replacements }
    if (replacements.amount && !Number.isNaN(replacements.amount)) {
      replacements.amount = toRealFormat(replacements.amount)
    }
    if (replacements.balance && !Number.isNaN(replacements.balance)) {
      replacements.balance = toRealFormat(replacements.balance)
    }
    if (replacements.value && !Number.isNaN(replacements.value)) {
      replacements.value = toRealFormat(replacements.value)
    }

    return replacements
  }

  const treatedReplacements = getTreatedReplacements()

  return (
    <Dropdown.Item
      className={
        isReadNotification
          ? styles.notifNova + ' ' + notification.title
          : notification.title
      }
      onClick={() => handleNotificationClick(notification)}
    >
      <div>
        <Markdown>
          {t(`notifications.bodys.${notification.title}`, treatedReplacements)}
        </Markdown>
        <span className={styles.notifDate}>{dateDiff}</span>
      </div>
    </Dropdown.Item>
  )
}
