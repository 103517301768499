import React from 'react'
import { useTranslation } from '../../services/translation'
import { toRealFormat } from '../../utils/currency-format.util'
import { useSettings } from '../../contexts/settings'
import { Spinner } from 'react-bootstrap'
import { useLocales } from '../../contexts/locales'

const Price = ({ value }) => {
  const { t } = useTranslation()
  const { settings, loading } = useSettings()
  const { currency } = useLocales()

  if (loading) {
    return (
      <div className='text-center'>
        <Spinner animation='border' />
      </div>
    )
  }

  value = parseFloat(value)

  if (currency === 'BRL') {
    return `${t('moeda.valor')}${toRealFormat(value.toFixed(2))}`
  }

  const price = value / settings.dollarQuote
  return `$${price.toFixed(2)}*`
}

export default Price
