import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from '../../services/translation'
import Link from 'next/link'
import { LinkBlog } from '../../utils/linkBlog'
import Image from '../Image'
import imageLoader from '../../utils/imageLoader'
import styles from './style.module.scss'

function Footer() {
  const { t } = useTranslation()
  return (
    <div className={styles.bgFooter}>
      <footer className={styles.homeFooter + ' pt-5'}>
        <Container>
          <Row>
            <Col xs={12} sm={6} md={6} lg={3}>
              <p>{t('menubottom.txt')}</p>
            </Col>

            <Col xs={12} sm={6} md={6} lg={3}>
              <h5>{t('menubottom.tit1')}</h5>
              <ul>
                <li>
                  <Link href='/helpcontact'>
                    <a title={t('menubottom.item5')}>{t('menubottom.item5')}</a>
                  </Link>
                </li>
                <li>
                  <Link href='/terms'>
                    <a title={t('menubottom.item7')}>{t('menubottom.item7')}</a>
                  </Link>
                </li>
                <li>
                  <Link href='/faq'>
                    <a title={t('menubottom.item8')}>{t('menubottom.item8')}</a>
                  </Link>
                </li>
                <li>
                  <Link href='/aboutus'>
                    <a title={t('menubottom.item9')}>{t('menubottom.item9')}</a>
                  </Link>
                </li>
                <li>
                  <Link href='/howworks'>
                    <a title={t('menubottom.item10')}>
                      {t('menubottom.item10')}
                    </a>
                  </Link>
                </li>
                <li>
                  <LinkBlog>Blog</LinkBlog>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3}>
              <h5>{t('menubottom.seguranca')}</h5>
              <div className='d-flex'>
                <div className={styles.paymentsFooter}></div>
                <div className={styles.pix}></div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3}>
              <h5>{t('menubottom.partners')}</h5>
              <Image
                src='/assets/img/logo_lockup_cloud_rgb.png'
                alt='Google Cloud'
                layout='responsive'
                height={59}
                width={306}
                placeholder='blur'
                blurDataURL={imageLoader(306, 59)}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <p>CO3D all rights reserved - 2022</p>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className='text-end'>
              <a
                href='https://www.instagram.com/co3d.art/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-instagram' />{' '}
              </a>
              <a
                href='https://www.facebook.com/co3d.art/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-facebook' />{' '}
              </a>
              <LinkBlog>
                <i className='fa fa-rss' />{' '}
              </LinkBlog>
              <a
                href='https://www.youtube.com/channel/UCZPgfkAizmku5gruvOlPn2w'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-youtube-play' />{' '}
              </a>
              <a
                href='https://www.linkedin.com/company/co3d/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-linkedin' />{' '}
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
      <Container>
        <p className={styles.seoTexts}>
          {t('seo.desc')} {t('seo.modelimgs')}
        </p>
        <p className='alertaestorno'>{t('menubottom.aviso')}</p>
      </Container>
    </div>
  )
}

export default Footer
