import React, { useEffect, useState } from 'react'
import { Nav, Dropdown, Modal } from 'react-bootstrap'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useTranslation } from '../../../../services/translation'
import { NotificationItem } from '../NotificationItem'
import { useNotification } from '../../../../contexts/notification'
import styles from './style.module.scss'
import modalstyles from '../../../../css/modalnotifications.module.scss'

const GET_NOTIF = gql`
  query Notification($skip: Int!, $limit: Int!) {
    notifications(adminNotifications: false, skip: $skip, limit: $limit) {
      notifications {
        id
        title
        replacements
        readedAt
        createdAt
      }
      hasUnread
    }
  }
`

const limit = 8

export const Notifications = ({ loadingUser, user }) => {
  const [show, setShow] = useState(false)
  const [page, setPage] = useState(0)
  const { t } = useTranslation()
  const { data, fetchMore, refetch } = useQuery(GET_NOTIF, {
    fetchPolicy: 'network-only',
    skip: loadingUser || !user,
    variables: {
      limit,
      skip: 0,
    },
  })
  const { onMessage } = useNotification()

  useEffect(() => {
    const handleReceiveMessage = async () => {
      await refreshNotifications()
    }
    return onMessage(handleReceiveMessage)
  }, [])

  const handleCloseModal = () => {
    setShow(false)
  }

  const handleShowModal = () => {
    setShow(true)
    if (page === 0) {
      fetchMoreNotifications()
    }
  }

  const maxNotificationsLength = (page + 1) * limit

  const fetchMoreNotifications = () => {
    if (
      fetchMore &&
      data.notifications.notifications.length >= maxNotificationsLength
    ) {
      const newPage = page + 1
      setPage(newPage)
      fetchMore({
        variables: {
          limit,
          skip: newPage * limit,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          const merged = Object.assign({}, prev, {
            notifications: {
              __typename: prev.notifications.__typename,
              hasUnread: fetchMoreResult.notifications.hasUnread,
              notifications: [
                ...prev.notifications.notifications,
                ...fetchMoreResult.notifications.notifications,
              ],
            },
          })
          return merged
        },
      })
    }
  }

  const refreshNotifications = async () => {
    const totalLimit = (page + 1) * limit
    await refetch({
      limit: totalLimit,
      skip: 0,
    })
  }

  if (!data) return null

  const lastNotifications = data.notifications.notifications.slice(0, limit)

  return (
    <>
      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle className={styles.notifIcon} variant='secondary'>
          <i
            className={
              data.notifications.hasUnread
                ? styles.hasMsg + ' fa fa-bell'
                : 'fa fa-bell'
            }
          />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={styles.notif}
          align='end'
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  padding: 12,
                },
              },
            ],
          }}
        >
          {lastNotifications.length > 0 ? (
            <>
              {lastNotifications.map(notification => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  refreshNotifications={refreshNotifications}
                />
              ))}
              {lastNotifications.length >= limit && (
                <Dropdown.Item
                  className={styles.ntfVertodas}
                  onClick={() => handleShowModal()}
                >
                  {t('notifications.todos')}
                </Dropdown.Item>
              )}
            </>
          ) : (
            <Dropdown.Item className={styles.ntfSemnotif} disabled>
              {t('notifications.semnotifi')}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={show}
        onHide={handleCloseModal}
        aria-labelledby='contained-modal-title-vcenter'
        className={modalstyles.modalNotif}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('notifications.tit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.notif + ' w-100'}>
          {data.notifications.notifications.map(notification => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              refreshNotifications={refreshNotifications}
              onClick={handleCloseModal}
            />
          ))}
          {data.notifications.notifications.length >=
            maxNotificationsLength && (
            <Dropdown.Item
              className={styles.ntfVertodas}
              onClick={() => fetchMoreNotifications()}
            >
              {t('notifications.todos')}
            </Dropdown.Item>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
