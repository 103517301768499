import { useEffect, useState } from 'react'
import {
  Nav,
  Navbar,
  Dropdown,
  Container,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useTranslation } from '../../services/translation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useBalance } from '../../contexts/balance'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { useAuth } from '../../contexts/auth'
import { useLocales } from '../../contexts/locales'
import { isAuthenticated } from '../../services/auth'
import { toRealFormat } from '../../utils/currency-format.util'
import { Notifications } from './components/Notifications'
import { LocaleMessage } from './components/LocaleMessage'
import Price from '../Price'
import styles from './style.module.scss'
import SearchAll from '../SearchAll'
import { _getCookie, _setCookie } from '../../services/cookie'
import Image from '../Image'

const GET_USER_BALANCE = gql`
  query getUserInfo {
    getUser {
      balance
      balancePending
      balanceChargeback
      balanceCollection
      picture
      slug
    }
  }
`

function Header() {
  const { t, lang } = useTranslation()
  const { balance, balancePending, setBalance } = useBalance()
  const { loadingUser, user, signOut, updateUser } = useAuth()
  const { changeLanguage, currency, preferredLanguage } = useLocales()
  const [open, setOpen] = useState(0)
  const [theme, setTheme] = useState('')
  let router = useRouter()

  const { data: userData } = useQuery(GET_USER_BALANCE, {
    fetchPolicy: 'network-only',
    skip: loadingUser || !user,
  })

  useEffect(() => {
    setTheme(_getCookie('darkmode'))
  })

  useEffect(() => {
    if (userData && userData.getUser) {
      const {
        balance,
        balancePending,
        balanceChargeback,
        balanceCollection,
        picture,
        slug,
      } = userData.getUser
      setBalance(balance, balancePending, balanceChargeback, balanceCollection)
      updateUser({ picture, slug })
    }
  }, [userData])

  const handleLogout = async () => {
    await signOut()
    router.push('/login')
  }

  const handleToggleMenu = () => {
    setOpen(open === 1 ? 0 : 1)
  }
  const handleToggleSearch = () => {
    setOpen(open === 2 ? 0 : 2)
  }
  const chageTheme = () => {
    if (theme === 'dark') {
      _setCookie('darkmode', 'light', 5 * 365)
      document.documentElement.setAttribute('data-theme', 'light')
      setTheme('light')
    } else {
      document.documentElement.setAttribute('data-theme', 'dark')
      _setCookie('darkmode', 'dark', 5 * 365)
      setTheme('dark')
    }
  }

  const shortName = user && user.name.split(' ')
  const firstName = user && shortName[0]
  const lastName = user && shortName[shortName.length - 1]
  const userPicture = user?.picture || '/assets/img/default-picture.jpg'
  const userSlug = user?.slug || ''

  return (
    <header className={styles.headerGeral}>
      <LocaleMessage />
      <div className='bg-light'>
        <Container fluid>
          <Nav className={`${styles.topMenu} `}>
            <div className='d-none d-lg-flex flex-grow-1 align-items-center'>
              {process.env.NEXT_STATIC_FEATURE_CLUB === 'true' && (
                <>
                  <Link href='/club' passHref>
                    <Nav.Link title='CO3DCLUB'>
                      <span>CO3D</span>CLUB
                    </Nav.Link>
                  </Link>

                  <i
                    className={
                      'd-none d-xl-block fa fa-circle ' + styles.bullet
                    }
                    aria-hidden='true'
                  ></i>
                </>
              )}
              <Nav.Item className='text-uppercase'>
                <Link href='/addproject' passHref>
                  <Nav.Link title={t('menu.itemvender')}>
                    {t('menu.itemvender')}
                  </Nav.Link>
                </Link>
              </Nav.Item>

              <i
                className={'d-none d-xl-block fa fa-circle ' + styles.bullet}
                aria-hidden='true'
              ></i>
              <Nav.Item className='text-uppercase'>
                <Link href='/addrequest' passHref>
                  <Nav.Link title={t('menu.itempedido')}>
                    {t('menu.itempedido')}
                  </Nav.Link>
                </Link>
              </Nav.Item>
            </div>

            <div className='d-flex flex-fill justify-content-end'>
              <Nav.Item>
                <Nav.Link
                  title={t('menu.item2')}
                  onClick={() => chageTheme()}
                  className={styles.darkmodeIcon + ' pe-0 pb-0'}
                >
                  <Image
                    height={23}
                    width={45}
                    src={
                      theme === 'dark'
                        ? '/assets/img/icon-dark.svg'
                        : '/assets/img/icon-light.svg'
                    }
                    alt='Theme switch'
                  />
                </Nav.Link>
              </Nav.Item>
              {!isAuthenticated(user) ? (
                <>
                  <Nav.Item>
                    <Link href='/login' passHref>
                      <Nav.Link title={t('menu.item1')}>
                        {t('menu.item1')}
                      </Nav.Link>
                    </Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link href='/presignup' passHref>
                      <Nav.Link title={t('menu.item2')}>
                        {t('menu.item2')}
                      </Nav.Link>
                    </Link>
                  </Nav.Item>
                </>
              ) : (
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle variant='secondary' id='dropdown-account'>
                    <img
                      src={userPicture}
                      className={styles.profilePicMini + ' rounded-circle '}
                      alt={user.name}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className={styles.dropDownProfile + ' text-center'}
                    align='end'
                    popperConfig={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 0],
                          },
                        },
                        {
                          name: 'preventOverflow',
                          options: {
                            padding: 12,
                          },
                        },
                      ],
                    }}
                  >
                    <img
                      src={userPicture}
                      className={styles.profilePicBig + ' rounded-circle m-3'}
                      alt={user.name}
                    />
                    <h4>{`${firstName}${
                      lastName !== firstName ? ' ' + lastName : ''
                    }`}</h4>
                    <Link href='/history' passHref>
                      <Nav.Link
                        title={t('minhaconta.sidemenu.5')}
                        className={styles.linkSimple}
                      >
                        {t('moeda.valor')}
                        {toRealFormat(balance)}
                        {currency !== 'BRL' && (
                          <OverlayTrigger
                            placement='bottom'
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>{t('moeda.tooltip')}</Tooltip>}
                          >
                            <span className='ms-2 small'>
                              (<Price value={balance} />)
                            </span>
                          </OverlayTrigger>
                        )}
                      </Nav.Link>
                    </Link>
                    {balancePending > 0 && (
                      <Link href='/history' passHref>
                        <Nav.Link
                          title={t('minhaconta.sidemenu.5')}
                          className={`${styles.linkSimple} ${styles.linkBalancePending} small`}
                        >
                          {t('moeda.valor')} {toRealFormat(balancePending)}{' '}
                          {t('moeda.liberar')}
                          {currency !== 'BRL' && (
                            <OverlayTrigger
                              placement='bottom'
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>{t('moeda.tooltip')}</Tooltip>}
                            >
                              <span className='ms-2 small'>
                                (<Price value={balancePending} />)
                              </span>
                            </OverlayTrigger>
                          )}
                        </Nav.Link>
                      </Link>
                    )}
                    <i
                      className={
                        'd-none d-lg-block fa fa-circle mb-3 mt-2 ' +
                        styles.bullet
                      }
                      aria-hidden='true'
                    ></i>
                    <Link href={'/p/' + userSlug} passHref>
                      <Nav.Link
                        title={t('minhaconta.perfilpub')}
                        className={styles.linkBlue}
                        target='_blank'
                      >
                        {t('minhaconta.perfilpub')}
                      </Nav.Link>
                    </Link>
                    <Link href='/account' passHref>
                      <Nav.Link
                        title={t('menu.item6')}
                        className={styles.linkBlue}
                      >
                        {t('menu.item6')}
                      </Nav.Link>
                    </Link>
                    <Link href='/chipinorganize' passHref>
                      <Nav.Link
                        title={t('minhaconta.sidemenu.2')}
                        className={styles.linkBlue}
                      >
                        {t('minhaconta.sidemenu.2')}
                      </Nav.Link>
                    </Link>
                    <Link href='/chipinparticipation' passHref>
                      <Nav.Link
                        title={t('minhaconta.sidemenu.3')}
                        className={styles.linkBlue}
                      >
                        {t('minhaconta.sidemenu.3')}
                      </Nav.Link>
                    </Link>
                    <Link href='/chipinmodeling' passHref>
                      <Nav.Link
                        title={t('minhaconta.sidemenu.4')}
                        className={styles.linkBlue}
                      >
                        {t('minhaconta.sidemenu.4')}
                      </Nav.Link>
                    </Link>
                    <Nav.Link
                      title={t('menu.item8')}
                      className={styles.linkBlue}
                      onClick={handleLogout}
                    >
                      {t('menu.item8')}
                    </Nav.Link>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle variant='secondary' id='dropdown-flag'>
                  {lang === 'pt' && (
                    <>
                      <img
                        className={styles.flagsLang}
                        src='/assets/flags/brazil.svg'
                        alt='CO3D'
                      />{' '}
                      PT
                    </>
                  )}
                  {lang === 'en' && (
                    <>
                      <img
                        className={styles.flagsLang}
                        src='/assets/flags/united-states-of-america.svg'
                        alt='CO3D'
                      />{' '}
                      EN
                    </>
                  )}
                  {lang === 'es' && (
                    <>
                      <img
                        className={styles.flagsLang}
                        src='/assets/flags/spain.svg'
                        alt='CO3D'
                      />{' '}
                      ES
                    </>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align='end'
                  popperConfig={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 0],
                        },
                      },
                    ],
                  }}
                >
                  {router.locales.map(locale => (
                    <Link
                      key={locale}
                      href={router.asPath}
                      locale={locale}
                      passHref
                    >
                      <Dropdown.Item
                        onClick={() => changeLanguage(locale, currency)}
                        active={false}
                      >
                        {
                          {
                            en: 'English',
                            es: 'Español',
                            pt: 'Português',
                          }[locale]
                        }
                      </Dropdown.Item>
                    </Link>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle variant='secondary' id='dropdown-money'>
                  {currency}
                </Dropdown.Toggle>
                <Dropdown.Menu align='end'>
                  <Dropdown.Item
                    onClick={() => changeLanguage(preferredLanguage, 'BRL')}
                  >
                    BRL (R$)
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => changeLanguage(preferredLanguage, 'USD')}
                  >
                    USD ($)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {isAuthenticated(user) && (
                <Notifications loadingUser={loadingUser} user={user} />
              )}
            </div>
          </Nav>
        </Container>
      </div>

      <Container fluid>
        <Navbar
          collapseOnSelect
          expand='lg'
          className={`${styles.menuBase} justify-content-start`}
          expanded={open === 1}
          onToggle={handleToggleMenu}
        >
          <Link href='/' passHref>
            <Navbar.Brand className='p-0'>
              <img
                className={styles.co3dLogo}
                src='/assets/img/co-3d-logo.svg'
                alt='CO3D'
              />
            </Navbar.Brand>
          </Link>
          <button
            className={styles.btSearchMob}
            onClick={handleToggleSearch}
            aria-controls='example-collapse-text'
            aria-expanded={open === 2}
          >
            <i className='fa fa-search' />
          </button>
          {process.env.NEXT_STATIC_FEATURE_CLUB === 'true' && (
            <Link href='/cart' passHref>
              <Nav.Link className='d-lg-none iconCart'>
                <i className='fa fa-shopping-cart' />
              </Nav.Link>
            </Link>
          )}
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='flex-fill align-items-center'>
              <div className='d-none d-lg-block flex-fill'>
                <SearchAll />
              </div>
              <Link href='/modelsmarketplace' passHref>
                <Nav.Link title={t('menu.m3')}>{t('menu.m3')}</Nav.Link>
              </Link>
              <i
                className={'d-none d-xl-block fa fa-circle ' + styles.bullet}
                aria-hidden='true'
              ></i>
              <Link href='/modelsincampaign' passHref>
                <Nav.Link title={t('menu.m2')}>{t('menu.m2')}</Nav.Link>
              </Link>
              <i
                className={'d-none d-xl-block fa fa-circle ' + styles.bullet}
                aria-hidden='true'
              ></i>

              <Link href='/modelsfindmodeler' passHref>
                <Nav.Link title={t('menu.m1')}>{t('menu.m1')}</Nav.Link>
              </Link>
              <i
                className={'d-lg-none fa fa-circle ' + styles.bullet}
                aria-hidden='true'
              ></i>
              {process.env.NEXT_STATIC_FEATURE_CLUB === 'true' && (
                <Link href='/club' passHref>
                  <Nav.Link title='CO3DCLUB' className='d-lg-none'>
                    <span>CO3D</span>CLUB
                  </Nav.Link>
                </Link>
              )}

              <Link href='/addproject' passHref>
                <Nav.Link
                  title={t('menu.itemvender')}
                  className='d-lg-none text-uppercase'
                >
                  {t('menu.itemvender')}
                </Nav.Link>
              </Link>
              <Link href='/addproject' passHref>
                <Nav.Link
                  title={t('menu.itempedido')}
                  className='d-lg-none text-uppercase'
                >
                  {t('menu.itempedido')}
                </Nav.Link>
              </Link>

              {process.env.NEXT_STATIC_FEATURE_CLUB === 'true' && (
                <>
                  <i
                    className={
                      'd-none d-xl-block fa fa-circle ' + styles.bullet
                    }
                    aria-hidden='true'
                  ></i>
                  <Link href='/cart' passHref>
                    <Nav.Link
                      className={
                        'd-none d-lg-block btn-cart btn-paralel px-2 ms-lg-3'
                      }
                    >
                      <span className='skew-fix d-flex justify-content-center'>
                        <i className='fa fa-shopping-cart' />
                      </span>
                    </Nav.Link>
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Collapse in={open === 2}>
          <div className='d-lg-none'>
            {/* Padding must be on children for smooth animation */}
            <SearchAll show={open === 2} className='px-3 pt-2 pb-4' />
          </div>
        </Collapse>
      </Container>
    </header>
  )
}

export default Header
