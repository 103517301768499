import React from 'react'
import ImageNext, { ImageProps } from 'next/image'

type Props = ImageProps

const Image: React.FC<Props> = ({ ...props }) => {
  const srcPath =
    typeof props.src === 'string'
      ? props.src
      : 'src' in props.src
      ? props.src.src
      : undefined
  return (
    <ImageNext
      unoptimized={srcPath?.startsWith('/') ? true : undefined}
      {...props}
    />
  )
}

export default Image
