import React, { useEffect, useState } from 'react'
import {
  getUserPreferredLocale,
  useTranslation,
} from '../../../../services/translation'
import I18nProvider from 'next-translate/I18nProvider'
import { LocaleMessageContent } from '../LocaleMessageContent'
import messagePt from '../../../../../public/locales/pt/locale-message.json'
import messageEn from '../../../../../public/locales/en/locale-message.json'
import messageEs from '../../../../../public/locales/es/locale-message.json'

export const LocaleMessage = () => {
  const { lang } = useTranslation()
  const [preferredUserLocale, setPreferredUserLocale] = useState()
  const [showLocaleMsg, setShowLocaleMsg] = useState(false)

  useEffect(() => {
    const preferredLocale = getUserPreferredLocale()
    const shouldShow = preferredLocale !== lang
    setPreferredUserLocale(preferredLocale)
    setShowLocaleMsg(shouldShow)
  }, [lang])

  if (!showLocaleMsg || !preferredUserLocale) return null

  const message = {
    pt: messagePt,
    en: messageEn,
    es: messageEs,
  }[preferredUserLocale]

  return (
    <I18nProvider lang={preferredUserLocale} namespaces={{ message }}>
      <LocaleMessageContent />
    </I18nProvider>
  )
}
