import React, { useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Button from '../Button'
import { useTranslation } from '../../services/translation'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import { deviceType } from 'react-device-detect'

const SearchAll = ({ search, show, className = '' }) => {
  const { t, lang } = useTranslation()
  const searchRef = useRef(null)
  const router = useRouter()
  const urlArr = router.asPath.split('/')
  const formik = useFormik({
    initialValues: {
      busca: '',
    },
    onSubmit: values => {
      if (urlArr[1] === 'allmodels') {
        window.location.href = `${
          lang === 'en' ? '' : `/${lang}`
        }/allmodels/search-${values.busca.replace('/', '%2F')}`
      } else
        router.push('/allmodels/search-' + values.busca.replace('/', '%2F'))
    },
  })
  useEffect(() => {
    if (deviceType === 'mobile' && show) {
      setTimeout(() => {
        searchRef.current.focus()
      }, 300)
    }
  }, [show])
  return (
    <div className={`busca-all ${className}`}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Control
            {...formik.getFieldProps('busca')}
            ref={searchRef}
            type='text'
            value={search}
            placeholder={t('forms.buscahome')}
          />
        </Form.Group>
        <Button className='btn-enviar-all' />
      </Form>
    </div>
  )
}

export default SearchAll
