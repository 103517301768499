import { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { _getCookie, _setCookie } from '../../services/cookie'

const Layout = ({ children, footerSvgshow, footerSeparatorShow }) => {
  useEffect(() => {
    if (_getCookie('darkmode')) {
      if (_getCookie('darkmode') === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark')
      } else {
        document.documentElement.setAttribute('data-theme', 'light')
      }
    } else {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        _setCookie('darkmode', 'dark', 5 * 365)
        document.documentElement.setAttribute('data-theme', 'dark')
      } else {
        _setCookie('darkmode', 'light', 5 * 365)
        document.documentElement.setAttribute('data-theme', 'light')
      }
    }
  })

  return (
    <>
      <Header />
      {children}
      <Footer
        footerSvgshow={footerSvgshow}
        footerSeparatorShow={footerSeparatorShow}
      />
    </>
  )
}

export default Layout

Layout.defaultProps = {
  footerSvgshow: true,
  footerSeparatorShow: true,
}
