import { useRouter } from 'next/router'
import styles from './LocaleMessage.module.scss'
import Link from 'next/link'
import { useTranslation } from '../../../../services/translation'

export const LocaleMessageContent = () => {
  const { t, lang } = useTranslation('message')
  const { asPath } = useRouter()

  return (
    <div className={styles.wrap}>
      <p>
        {t('text') + ' '}
        <Link href={asPath} locale={lang} passHref>
          <a>{t('link')}</a>
        </Link>
      </p>
    </div>
  )
}
