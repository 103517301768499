import { useTranslation } from '../services/translation'

export const LinkBlog = ({ en, pt, es, children, className }) => {
  const { lang } = useTranslation()

  let linkblog = ''
  if (lang === 'pt') {
    linkblog = 'https://blog.co3d.art/pt-br/' + pt
  }
  if (lang === 'es') {
    linkblog = 'https://blog.co3d.art/es-es/' + es
  }
  if (lang === 'en') {
    linkblog = 'https://blog.co3d.art/' + en
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a target='_blank' className={className} href={linkblog}>
      {children}
    </a>
  )
}

LinkBlog.defaultProps = {
  en: '',
  pt: '',
  es: '',
}
